import { template as template_9c293f95b2fd4e0494d7007f0a923af2 } from "@ember/template-compiler";
const FKLabel = template_9c293f95b2fd4e0494d7007f0a923af2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
